import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useDetail } from "seed/gql";
import { usePost, useGetCall } from "seed/api";
import { Loading } from "seed/helpers";
import { useLocation } from "react-router";
import { isUserAdmin } from "components/util/auth_util";
import View from "components/form/Form.view";

function Form(props) {

  const userId = sessionStorage.getItem("id");
  const formId = props.formId;

  let { search } = useLocation();
  let query = new URLSearchParams(search);
  
  const token = query.get("token");
  const [responseId, setResponseId] = useState(query.get("response_id"));
  
  const formRef = useRef(null);
  const [responseData, setResponseData] = useState({});
  const [initialValues, setInitialValues] = useState({});
  const [multipleData, setMultipleData] = useState({});
  const [isShareShown, setIsShareShown] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isInvitation, setIsInvitation] = useState(query.get("token") != null);
  const [submitType, setSubmitType] = useState("DRAFT");

  const isEdit = isAdmin;
  const isOwner = !responseData?.user?.id || responseData?.user?.id == userId;
  let userType = "GUEST";
  if (isOwner) userType = "OWNER";
  else if (isAdmin) userType = "ADMIN";

  function isPositiveInteger(n) {
    return n >>> 0 === parseFloat(n);
  }

  function initMultipleData(o, parent = "", path = "") {
    for (var i in o) {
      if (isPositiveInteger(i)) {
        multipleData[(path != "" ? path + "." + parent : parent)] = o.length
        setMultipleData(multipleData)
      }
      if (o[i] !== null && typeof (o[i]) == "object") {
        if (!isPositiveInteger(parent)) {
          initMultipleData(o[i], i, parent != "" ? (path != "" ? (path + "." + parent) : parent) : "");
        } else {
          let numParent = "[" + parent + "]"
          initMultipleData(o[i], i, parent != "" ? (path != "" ? (path + numParent) : numParent) : "");
        }
      }
    }
  }

  function preProcessMultipleData(o, parent = "", path = "", parentObj) {
    let bk = false
    for (var i in o) {
      if (isPositiveInteger(i)) {
        let currentLength = o.length
        let storedLength = multipleData[(path != "" ? path + "." + parent : parent)] ?? 1
        if (currentLength > storedLength) {
          parentObj[parent] = parentObj[parent].slice(0, storedLength - currentLength)
          bk = true
        }
      }
      if (o[i] !== null && typeof (o[i]) == "object") {
        if (!isPositiveInteger(parent)) {
          preProcessMultipleData(o[i], i, parent != "" ? (path != "" ? (path + "." + parent) : parent) : "", o);
        } else {
          let numParent = "[" + parent + "]"
          preProcessMultipleData(o[i], i, parent != "" ? (path != "" ? (path + numParent) : numParent) : "", o);
        }
      }
      if (bk) break;
    }
  }

  if (formId != null)
    localStorage.setItem("formId", formId);

  const qForm = useDetail(`
    {
      form {
        name
        settings
        fields {
          name
          type
          isMultiple
          settings
          subFields {
            name
            type
            isMultiple
            settings
            subFields {
              name
              type
              isMultiple
              settings
            }
          }
        }
      }
    }`, formId)

  const qResponse = useDetail(`{
    response {
      id
      token
      values
      user {
        id
        firstName
        lastName
      }
      invitations {
        id
        email
      }
    }
  }`, responseId, {
    onCompleted: (data) => {

      if(token && token != data.response.token) {
        if(isAdmin) return window.location.href = "/admin"
        return window.location.href = "/logout"
      }

      formRef.current?.setValues(data.response.values)

      setResponseData(data.response)
      setInitialValues(data.response.values)
      initMultipleData(data.response.values)

    }
  })

  const [callAuth, reqCall] = useGetCall("/auth/user", "", {
    onCompleted: data => {
      setIsAdmin(isUserAdmin(data.username))
      if(isEdit && !isAdmin) return window.location.href = "/logout"
    },
    onError: () => {
      window.location.href = "/logout"
    }
  })  

  const [cSaveResponse, qSaveResponse] = usePost("/responses/save_form", {
    onCompleted: (data) => {
      window.location.href = "/submitted_form/" + submitType + "/" + formId
    }
  });

  const [cEditResponse, qEditResponse] = usePost("/responses/edit_form", {
    onCompleted: (data) => {
      if(isAdmin && data.type == "EDIT") window.location.href = "/admin"
      else window.location.href = "/submitted_form/" + submitType + "/" + formId
    }
  });

  useEffect(() => {
    qResponse.refetch();
  }, [responseId, formId, token]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => callAuth(), [isAdmin]); // eslint-disable-line react-hooks/exhaustive-deps

  if (qForm.loading || qSaveResponse.loading || qEditResponse.loading || qResponse.loading)
    return <main id="content">
      <div
        class="position-fixed top-0 right-0 left-0 bg-img-hero"
        style={{
          height: "32rem",
          marginRight: "20px",
          backgroundImage: "url(/theme/svg/components/abstract-bg-4.svg)"
        }}
      ></div>
      <div class="container py-10 py-sm-7" style={{ height: "30rem" }}>
        <Loading color="#fff" class="mt-10" />
      </div>
    </main>

  if (qForm.error) return ""
  
  const onSelectResponse = (response) => {

    setIsInvitation(response.invitation != null);

    if(response?.type == "FINAL" && window.confirm("Deseas utilizar la información del último formulario enviado como base?")) {
      setResponseData(response)
      setInitialValues(response.values)
      initMultipleData(response.values)
    }
    else {
      setResponseData(response)
      setResponseId(response.id)
      setInitialValues(response.values ?? {})
      initMultipleData(response.values ?? {})
    }

  }

  const onSubmit = (values) => {

    preProcessMultipleData(values)

    if (submitType == "FINAL") {
      if (window.confirm("¿Estás seguro de enviar la información final?"))
        return cSaveResponse({ values: values, form_id: formId, type: submitType, response_id: responseId });
    } 
    else {
      if(responseId) return cEditResponse({ values: values, response_id: responseId, type: submitType } )
      return cSaveResponse({ values: values, form_id: formId, type: submitType });
    }

  }

  let formData = { ...qForm.data.form }
  formData.settings = (qForm.data.form.settings)

  // Recursively parse settings
  const parseSettings = (fields) =>
    fields.map(field => {

      const newField = Object.assign({}, field);
      if (newField?.subFields?.length > 0) {
        newField.subFields = parseSettings(newField.subFields)
        newField.subFields.sort((f1, f2) => f1.id - f2.id)
      }
      return newField;

    });

  let fieldsData = parseSettings(qForm.data.form.fields);
  fieldsData.sort((f1, f2) => f1.id - f2.id)

  const error = qSaveResponse.error ? "Ha ocurrido un error al cargar tu información, prueba más tarde" : null;

  return <View
    formRef={formRef}
    userId={userId}
    formId={formId}
    responseId={responseId}
    responseData={responseData}
    formData={formData}
    initialValues={initialValues}
    isEdit={isEdit}
    isOwner={isOwner}
    isInvitation={isInvitation}
    userType={userType}
    fieldsData={fieldsData}
    multipleData={multipleData}
    isShareShown={isShareShown}
    isAdmin={isAdmin}
    setMultipleData={setMultipleData}
    setIsShareShown={setIsShareShown}
    setResponseId={setResponseId}
    setResponseData={setResponseData}
    setSubmitType={setSubmitType}
    error={error}
    onSubmit={onSubmit}
    onSelectResponse={onSelectResponse}
  />;

}

Form.propTypes = {
  onCompleted: PropTypes.func,
  onError: PropTypes.func,
  history: PropTypes.object
};

export default Form;