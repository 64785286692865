import React, { useEffect } from "react";
import { useDetail } from "seed/gql";
import { useGetCall } from "seed/api";
import { Loading } from "seed/helpers";
import { isUserAdmin } from "components/util/auth_util";
import View from "components/form/Responses.view";

function Responses({
  formId,
  history
}) {

  const userId = sessionStorage.getItem("id");

  const qUser = useDetail(`{
    user {
      formResponses {
        id
        type
        version
        token
        values
        user {
          id
          firstName
          lastName
        }
      }
    }
  }`, userId);

  const [callAuth, reqCall] = useGetCall("/auth/user", "", {
    onCompleted: (data) => {
      if(isUserAdmin(data.username)) return window.location.href = "/admin";
    },
    onError: () => {
      window.location.href = "/logout";
    }
  })  

  useEffect(() => callAuth(), []); // eslint-disable-line react-hooks/exhaustive-deps

  if (qUser.loading)
    return <main id="content">
      <div
        class="position-fixed top-0 right-0 left-0 bg-img-hero"
        style={{
          height: "32rem",
          marginRight: "20px",
          backgroundImage: "url(/theme/svg/components/abstract-bg-4.svg)"
        }}
      ></div>
      <div class="container py-10 py-sm-7" style={{ height: "30rem" }}>
        <Loading color="#fff" class="mt-10" />
      </div>
    </main>

  if (qUser.error) return ""
  
  let { formResponses = [] } = qUser.data?.user ?? {};

  return <View
    userId={userId}
    formId={formId}
    responses={formResponses}
  />;

}

export default Responses;