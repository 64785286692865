import React from "react";
import { Link } from "react-router-dom";

const ResponsesView = ({
  userId,
  responses,
}) => (
  <>
    <div class="row justify-content-center mt-4">
      <div class="col-md-10">
        <div class="card card-lg mb-5">
          <div class="card-body">
            <div class="row justify-content-center">
              <div class="col-lg-11 text-center pl-6 pr-6 pb-8" style={{ backgroundColor: "white" }}>

                <div class="d-flex justify-content-between w-100">
                  <div>
                    <h4 class="mb-5">Formularios</h4>
                  </div>
                  <div>
                    <Link
                      to="/response"
                      class="btn btn-primary"
                    >
                      Nuevo formulario
                    </Link>
                  </div>
                </div>

                <div class="table-responsive">
                  <table class="table table-striped table-bordered table-hover">
                    <thead>
                      <tr>
                        <th></th>
                        <th>Área de práctica</th>
                        <th>Acciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        responses.map((response, idx) => 
                          <tr key={`response-${idx}`}>
                            <td>{idx + 1}</td>
                            <td>
                              {response?.values?.practice_area??"Sin especificar"}
                              {
                                response.user.id != userId &&
                                  ` (invitación de ${response.user.firstName} ${response.user.lastName})`
                              }
                            </td>
                            <td>
                              {
                                response.type == "DRAFT" 
                                  ? <Link 
                                      to={`/response?response_id=${response.id}`}
                                      class="btn btn-primary"
                                      style={{
                                        width: "100px",
                                      }}
                                    >
                                      Editar
                                    </Link>
                                  : <button
                                      disabled={true}
                                      class="btn btn-primary"
                                      style={{
                                        width: "100px",
                                      }}
                                    >
                                      Enviado
                                    </button>
                              }
                            </td>
                          </tr>
                        )
                      }
                      {
                        responses.length == 0 &&
                          <tr>
                            <td colSpan="3" class="text-center">No hay formularios</td>
                          </tr>
                      }
                    </tbody>
                  </table>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
);

export default ResponsesView;