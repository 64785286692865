import React, { useEffect } from "react";
import { usePost } from "seed/api";

export default function DebugLogin() {

  const [callDebugLogin] = usePost("/users/debug_login", {
    onCompleted: ({ key, user }) => {
      sessionStorage.setItem('token', key);
      sessionStorage.setItem('id', user);
      window.location.replace("/form");
    }
  });
  
  useEffect(() => {
    const username = prompt("Enter the email of the user you want to login as:", "")
    callDebugLogin({ username })
  }, []);

}