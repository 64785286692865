import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import Responses from "components/form/Responses";
import Form from "components/form/Form";
import Login from "components/auth/Login";
import LoginAuth from "components/auth/LoginAuth";
import Logout from "components/auth/Logout";
import SubmittedForm from "components/messages/SubmittedForm";
import Admin from "components/form/Admin";
import DebugLogin from "./auth/DebugLogin";

const App = () =>
  <div id="app">
    <BrowserRouter>
      <Switch>
        <Route path="/admin" component={Admin} />
        <Route path="/form/:form_id" component={(props) => (
          <main id="content">
            <div
              class="position-fixed top-0 right-0 left-0 bg-img-hero"
              style={{
                height: "32rem",
                backgroundImage: "url(/theme/svg/components/abstract-bg-4.svg)",
              }}
            >
              <figure class="position-absolute right-0 bottom-0 left-0">
                <svg
                  preserveAspectRatio="none"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 1921 273"
                >
                  <polygon fill="#00454d" points="0,273 1921,273 1921,0 " />
                </svg>
              </figure>
            </div>
            <div class="container py-5 py-sm-7">
              <BrowserRouter basename={`/form/${props.match.params.form_id}`}>
                <Switch>
                  <Route 
                    path="/responses" 
                    component={(subProps) => 
                      <Responses 
                        {...subProps}
                        key={Math.random()}
                        formId={props.match.params.form_id}
                      />
                    } 
                  />
                  <Route 
                    path="/response" 
                    component={(subProps) => 
                      <Form 
                        {...subProps}
                        key={Math.random()}
                        formId={props.match.params.form_id}
                      />
                    } 
                  />
                  <Redirect to="/responses" />
                </Switch>
              </BrowserRouter>
            </div>
          </main>
        )} />
        <Route path="/login" component={Login} />
        <Route path="/auth" component={LoginAuth} />
        <Route path="/logout" component={Logout} />
        <Route path="/debug_login" component={DebugLogin} />
        <Route path="/submitted_form/:type/:form_id" component={SubmittedForm} />
        <Redirect to="/form/1" />
      </Switch>
    </BrowserRouter>
  </div>;

App.propTypes = {};

export default App;